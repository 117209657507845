import { BulkPrice, Size } from "../app/shared/interfaces/product/enums";

export class Product {
  public id: number;
  public articleNumber: string;
  public name: string;
  public bulkPrices: BulkPrice[];
  public categories: string[];
  public groups: string[];
  public sizeType: string;
  public sizes: Size[];
  public properties: any;
  public title: any;
  public description: any;
  public url: string;
  public image: string;
  public size: number[];
  public retailPrice: number;
  public notFoundInDb?:boolean

  private _price: number = 0;
  private _amount: number;
  private _minOrderQuantity: number;
  private _isStock: boolean;

  constructor(data) {
    this.id = data.id ?? data._id ?? data.articleId;
    this.groups = data.groups ?? data._groups;
    this.articleNumber = data.articleNumber ?? data._articleNumber;
    this.name = data.name ?? data._name;
    this.categories = data.categories ?? data._categories;
    this.sizeType = data.sizeType ?? data._sizeType;
    this.price = data.price ?? data._price;
    this.retailPrice = data.retailPrice ?? data._retailPrice;
    this.sizes = data.sizes ?? data._sizes;
    this.properties = data.properties ?? data._properties;
    this.title = data.title ?? data._title;
    this.description = data.description ?? data._description;
    this.url = data.url ?? data._url;
    this.image = data.image ?? data._image;
    this.amount = data.amount ?? data._amount;
    this.bulkPrices = data.bulkPrices ?? data._bulkPrices;
    this.minOrderQuantity = data.minOrderQuantity ?? data._minOrderQuantity;
    this.isStock = data.isStock ?? data._isStock;
    this.size = data.size ?? data._size;
    this.notFoundInDb = data.notFoundInDb ?? data._notFoundInDb
  }

  get amount() {
    if (this.hasSizes && this.size) {
      return this.size.reduce((pv, cv) => {
        return (pv || 0) + (cv || 0);
      }, 0);
    } else {
      return this._amount;
    }
  }

  set amount(value: number) {
    if (!this.hasSizes) {
      this._amount = value;
    }
  }

  set price(price: number) {
    this._price = price;
  }

  get price(): number {
    if (!this.bulkPrices || this.bulkPrices.length === 0) {
      return this._price;
    } else {
      let lastAmount = 0;
      let price = this._price;
      for (const p of this.bulkPrices) {
        if (this.amount >= p.amount && p.amount > lastAmount) {
          lastAmount = p.amount;
          price = p.price;
        }
      }
      return price;
    }
  }

  get totalPrice() {
    return this.amount ? this.price * this.amount : this.price;
  }

  set isStock(isStock: boolean) {
    this._isStock = isStock;
  }

  get isStock(): boolean {
    return this._isStock || this.isAccessory
      ? true
      : this.hasSizes && this.sizes && this.sizes[0]?.status !== -1;
  }

  get isAccessory(): boolean {
    return this.categories?.[0] === "Z01";
  }

  get hasSizes(): boolean {
    if (this.sizeType) {
      return this.sizeType.trim() !== "";
    } else {
      return false;
    }
  }

  set minOrderQuantity(quantity: number) {
    this._minOrderQuantity = 1;

    if (quantity > 1) {
      this._minOrderQuantity = quantity;
    } else if (!this.isStock) {
      this._minOrderQuantity = 10;
    }
  }

  get minOrderQuantity(): number {
    return this._minOrderQuantity;
  }

  get isBuyable(): boolean {
    return this.amount >= this._minOrderQuantity;
  }
}

// LeanProduct is a Product with reduced data (e.g. in order history items).
export class LeanProduct extends Product {
  public isStockProduct: boolean;
  public vanillaPrice: number;
  public posnr: number;

  constructor(data) {
    super(data);
    this.isStockProduct = data.isStockShoe;
    this.posnr = data.posnr;
    this.vanillaPrice = data.vanilla_price;
    if (!this.title) {
      this.title = {
        de: this.id,
        en: this.id,
      };
    }
  }
}
